import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import styles from '../styles/contact.module.sass'

export default ({ pageContext }) => {
  const { data } = pageContext

  return (
    <Layout data={data} heroBodyClasses={styles.heroBody}>
      <SEO {...data.seo} />
    </Layout>
  )
}
